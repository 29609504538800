
.ql-snow .ql-picker.ql-table .ql-picker-label::before,
.ql-snow .ql-picker.ql-table .ql-picker-item::before {
  content: '\f0ce'; /* FontAwesome 테이블 아이콘 코드 */
  font-family: 'FontAwesome';
}

.ql-snow .ql-picker.ql-table .ql-picker-label,
.ql-snow .ql-picker.ql-table .ql-picker-item {
  opacity: 1; /* 아이콘을 불투명하게 설정 */
}

/* 테이블 테두리 스타일 조정 */
.ql-editor .ql-table {
    border-collapse: collapse;
  }
  
  .ql-editor .ql-table th,
  .ql-editor .ql-table td {
   
   
    border: 1px solid #ccc; /* 셀 테두리 색상 및 스타일 조정 */
  }

  