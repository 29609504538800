html, body {
    font-family: 'Roboto', sans-serif;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
  }
  
  #root {
    height: 100vh;
  }
  
  #RoomList{
    width: 550px;
    background-color: white;
    margin-left: 30px;
    border-radius: 15px;
    
  }
  * {
    box-sizing: border-box;
  }
  
  .joinOuterContainer {
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: #f7f7f7;
    box-shadow: 2px 2px 2px 2px #f1f1f1;
  }
  .joinContainerBox {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    width: 1024px;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .joinInnerContainer {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  
  .joinInput {
    border-radius: 15px;
    border-width: 0px;
    padding: 15px 20px;
    width: 100%;
    box-shadow: 2px 2px 2px 2px #f1f1f1;
  }
  
  .heading {
    color: black;
    font-size: 2.5em;
    padding-bottom: 10px;
  }
  
  .SignInBtn {
      color: white !important;
      text-transform: uppercase;
      text-decoration: none;
      background: #333;
      padding: 20px;
      border-radius: 5px;
      display: inline-block;
      border: none;
      width: 100%;
  }
  
  .mt-20 {
    margin-top: 20px;
  }
  @media (min-width: 480px) and (max-width: 1024px) {
    .joinOuterContainer {
      height: 100%;
      width: 100%;
      overflow: scroll;
    }
    .joinContainerBox {
      height: 100%;
      margin-bottom: 20vh;
    }
    
    .joinInnerContainer {
      display: flex;
      flex-direction: column;
      width: 90%;
    }
  
  
  }
  
  .SignInBtn:focus {
    outline: 0;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    .joinOuterContainer {
      height: 100%;
      width: 100%;
      overflow: scroll;
    }
    .joinContainerBox {
      height: 100%;
      margin-bottom: 20vh;
    }
    .joinInnerContainer {
      display: flex;
      flex-direction: column;
      width: 90%;
    }
  
  
  }
  
  .SignInBtn:focus {
    outline: 0;
  }