.M_공지사항_헤더{
  margin: 0px 0px 0px 5px;
  text-align: center;
} 
#MobilelTabPage{
  width: 100%;
}
#WrapperBox.Wrapper {
  align-items: center;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  overflow:overlay;
  background-color: #f7f7f7;
}
#MobilelTabPage a,#MobilelTabPage a:link,#MobilelTabPage a:visited,#MobilelTabPage a:active,#MobilelTabPage a:hover{
  color: black;
  text-decoration: none;
     
}
.M_작성자_입력기{
  width: 50px;
}
.M_내용_입력기{
  width: 100%;
}
.M_QA_게시판{
  border-width: 0px;
  width:85%;
}
.M_QA_게시판 th{
  border-style: solid;
  border-left:1px solid silver;
  border-right:1px none silver;;
  border-top:1px solid silver;;
  border-bottom:1px solid silver;;
  color:black;
  font-size: 10px;
}
.M_QA_게시판 td{
  font-size: 8px;
}
.M_컨텐츠{
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 15vh;
}
.M_게시판_컨테이너{
  position: relative;
  font-size: 15px;
  border: 1;
  border-style: solid;
  border-color: white;
  border-radius: 15px;
  padding: 10px;
  margin-bottom: 40px;
  background-color:#fff;
  box-shadow: 2px 2px 2px 2px #f1f1f1;
}
.M_공지사항_게시판{
  border-width: 0px;
  width:100%;
}
.M_공지사항_게시판 td a{
  overflow: hidden;
  text-overflow: ellipsis;
  width: 50vw;
  height: 23px;
  white-space: nowrap;
  display: block; 
}
.pix img{
border-width: 1px;
border-style: solid;
border-color: #bdbdbd;
width: 25%;
}
.M_NoticeMasterPanel {
  padding: 1px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.M_CommunityBox {
  display: flex;
  width: 50%;
  margin-bottom: 3px;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 1024px) {
  .HomeMasterPanel {
    max-width: 1200px;
    height: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
}
    
@media  screen and (min-width: 600px) and (max-width : 1024px)  {
  /* .HomeMasterPanel .BigImg{
    display: none;
  } */
  .HomeMasterPanel {
    max-width: 100%;
    /* max-width: 1200px; */
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 0vh;
    background-color: #f7f7f7;
  }
}
    
@media screen and (max-width: 600px){
  .HomeMasterPanel {
    max-width: 100%;
  }
}
/* css완료 */