.ChatList li{
    list-style: none;
    padding: 10px;
}
.ImgBtn img{
    width: 20px;
    margin-left: 10px;
}
.BtnBox{
    padding-left: 0;
    /* border-bottom: 1px solid #ccc; */
}
.BtnBox li{
    background-color: #efefef;
    border: none;
    border-radius: 5px;
    list-style: none;
    padding: 5px 10px;
    cursor: pointer;
}
.BtnBox li:hover{
    background-color: #00bed8;
    color: #fff;
}
.InputView button{
    border-radius: 5px;
    border-width: 0;
    padding: 5px 10px;
    background-color: #efefef;
    cursor: pointer;
}
.InputView button:hover{
    background-color: #00bed8;
}
.View{
    position: relative;
    margin-top: 5px;
}
.View::before{
    display: block;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    left: 10px;
    height: 1px;
    background-color: #ccc;
    margin-top: -10px;
}
.View::after{
    display: block;
    content: '';
    position: absolute;
    bottom: 0;
    right: 10px;
    left: 10px;
    height: 1px;
    background-color: #ccc;
}
.InputViewTop{
    margin: 10px 0;
    padding: 0;
}
.InputViewTop label{
    color: #000;
}
select{
    border-radius: 5px;
    padding: 3px;
}
.InputViewBottom textarea{
    width: 100%;
    height: 100px;
    resize: none;
    border-radius: 5px;
}
/* .InputViewBottom button{
    margin-right: 0;
    text-align: center;
} */
