#BizMemberView{
  width: 1200px;
  background-color: #fff;
}
#BizMemberView *,#BizMemberView *:before,#BizMemberView *:after {
    box-sizing: inherit;
    
  }
  #BizMemberView .Bizrow {
    display: flex;
    justify-content: center;
  }
  #BizMemberView .Bizrow > .Bizcol {
    width: 33.3%;
    min-width: 250px;
    margin-bottom: 16px;
    padding: 0 8px;
  }
  
  #BizMemberView .Bizcard {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin: 8px;
    background-color: #fefefe;
    border-radius: 10px;
  }
  
  #BizMemberView > .about-section {
    /* padding: 25px;
    text-align: center;
    background-color: #00bed8;
    color: white;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px; */
  }
  
  .containerBox {
    padding: 0 16px;
  }
  .containerBox > p{
    font-size: calc(10px + 1vmin);
  }
  
 .containerBox::after, #BizMemberView  > .Bizrow::after {
    content: "";
    clear: both;
    display: table;
  }
  
  #BizMemberView > .title {
    color: grey;
  }
  
  .Bizbutton {
    border: none;
    outline: 0;
    display: inline-block;
    padding: 10px;
    color: white;
    background-color: #000;
    text-align: center;
    cursor: pointer;
    width: 100%;
    border-radius: 7px;
  }
  
  .Bizbutton:hover {
    background-color: #555;
  }
  
  #BizMemberView > img {
      width: 350px auto;
      height: 350px auto;
      border-radius: 10px;
  }

  @media screen and (max-width: 650px)
  {
    #BizMemberView{
      width: 100%;
    }
    #BizMemberView .Bizcard {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      margin: 0px;
    }
    #BizMemberView .title {
      color: grey;
      font-size: 12px;
    }
    .containerBox > p{
      font-size: calc(7px + 1vmin);
    }
    #BizMemberView > h3 {
      margin: 3px;
    }
    
    #BizMemberView > .Bizrow {
      display: inline-flex;
      justify-content: center;
      width: 95%;
    }
    #BizMemberView .Bizrow > .Bizcol {
      width: 50%;
      min-width: 150px;
      margin-bottom: 16px;
      padding: 0 8px;
    }
    #BizMemberView #email {
      font-size: 1;
    }
    .Bizbutton {
      padding: 5px;
    }

  }