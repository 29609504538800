/* RoomList.css */

.room-list-container {
    max-width: 1240px;
    margin: 20px auto;
    padding: 20px;
  }
  .room-list-container .room-list-top{
    display: flex;
    justify-content: space-between;
    margin: 35px 0;
  }
  .room-list-container .room-list-top h1 {
    font-size: 40px;
    margin-block-start: 0;
    margin-block-end: 0;
  }
  .room-list-container .room-list-top select{
    padding: 0 57.5px;
    font-size: 1rem;
    cursor: pointer;
    margin: 5px 0;
  }
  .room-list-container .room-list-top select option{
    /* padding: 10px 0; */
  }
  
  .room-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 30px;
    border-radius: 10px;
    text-decoration: none;
    color: #333;
    font-weight: bold;
    border: 3px solid #00bed8;

  }
  .room-item .img-container{
    width: 100px;
    height: 100px;
    border: 1px solid black;
    border-radius: 10px;
    position: relative;
  }
  .room-item .img-container img{
    object-fit: contain;
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
    display: block;
  }
  .room-item .img-container p{
    font-size: 2.5rem;
    color: #fff;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-block-start: 0;
  }
  .rooms-info{
    font-size: 1.75rem;
    margin-left: -47%;
    text-align: left;
    font-weight: bold;
  }
  .rooms-info img{
    width: 20px;
    height: 20px;
    margin-right: 3%;
  }
  .room-item .JoinBtn{
    background-color: #00bed8;
    padding: 25px 30px;
    color: #fff;
    border-radius: 10px;
    border: none;
    font-size: 20px;
    margin-left: 1%;
    cursor: pointer;
  }
  .room-item .JoinBtn:hover{
    background-color: #004a61;
    font-weight: bold;
  }