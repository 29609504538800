.Contents_Deactive{
    width: 0px;
    height: 0px;
}
.Contents_Active{
    width: 700px;
    border-width: 1px;
    border-style: solid;
    border-color:gainsboro;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.ContentsBox{
    padding-top: 10px;
    width: 100%;
    min-height: 200px;
    border-width: 1px;
    border-style: solid;
    border-color:gainsboro;
    position: relative;
    flex: 1;
    
}
.ContentsBox #Text{
    text-align: left;
}
.CommentBox{
    padding-bottom: 10px;
    flex: 2;
    width: 100%;
    min-height: 80px;
    border-width: 0px;
    border-style: solid;
    border-color:gainsboro;
    position: relative;
}
.Contents_Deactive .CommentBox{
    border-style: none;
    position: relative;
}

.Contents_Deactive .ContentsBox{
    border-style: none;
    position: relative;
}
.WriteButton{
    width: 100%;
    height: 50px;
    margin-top: 10px;
}

.본문입력기{
    width: 100%; 
    min-height: 250px; 
}

@media screen and (min-width: 600px) and (max-width : 1024px){

    .본문입력기{
        width: 100%; 
        min-height: 100px; 
    }
    .Contents_Deactive{
        width: 0px;
        height: 0px;
    }
    .Contents_Active{
        width: 100%;
        border-width: 0px;
        border-style: solid;
        border-color:gainsboro;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .ContentsBox{
        width: 100%;
        min-height: 200px;
        border-width: 0px;
        border-style: solid;
        border-color:gainsboro;
        position: relative;
        flex: 1;
    }
    .CommentBox{
        flex: 2;
        width: 100%;
        min-height: 80px;
        border-width: 0px;
        border-style: solid;
        border-color:gainsboro;
        position: relative;
    }
    .Contents_Deactive .CommentBox{
        border-style: none;
        position: relative;
    }

    .Contents_Deactive .ContentsBox{
        border-style: none;
        position: relative;
    }
    .WriteButton{
        width: 100%;
        height: 50px;
        margin-top: 10px;
    }
    .MobileBoard{
        display: flex;
        flex-direction: column;
        border-style: solid;
        border-width: 0px;
        border-bottom-width: 0px;
        width: 100%;
        height: 100%;
    }
    .MobileBoard ul{
        margin: 0px;
    }
    .MobileBoard li{
        margin: 0px;
    }
    .MobileBoardItem{
        display: flex;
        flex-direction: column;
        border-color: rgb(53, 52, 51);
        text-align: left;
        flex-direction: column;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        width: 100%;
        height:60px;
        margin:5px;
    }
    #Writer{
        display: flex;
        width:60%;
        margin-left: 40px;
        font-size: 8pt;
        line-height: 20px;
    }
    #Subject{
        display: flex;
        width: 100%;
    }

    
}
@media screen and (max-width: 600px){

    .본문입력기{
    width: 100%;
    min-height: 100px; 
}
    .MobileBoard{
        display: flex;
        flex-direction: column;
        align-items: center;
        border-top-style: solid;
        border-width: 0px;
        border-top-width: 1px;
        width: 100%;
        text-decoration:none;
        list-style-type: none;
    }
    .MobileBoard ul{
        margin: 0px;
    }
    .MobileBoard li{
        margin: 0px;
    }
    .MobileBoardItem{
        text-align: left;
        display: flex;
        flex-direction: column;
        border-color: rgb(226, 223, 222);
        flex-direction: column;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        width: 100%;
        height:60px;
        margin:5px;
    }
    #Writer{
        display: flex;
        width:60%;
        margin-left: 40px;
        font-size: 8pt;
        line-height: 20px;
    }
    #Subject{
        display: flex;
        width: 100%;
    }

}
 