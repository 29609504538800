#Board_View{
  margin-bottom: 150px;
}

.SubTabPage{
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  
}
.SubTabPage li {
  float: left;
  cursor: pointer;
}
.SubTabPage li a{
  cursor: pointer;
}
.SubNav{
    width: 1200px; 
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    
}
.SubNavCategory{
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: white;
  
}
.SubNavCategory li{
  float: left;
}
.SubNav li {
  float: left;
  
}
.SubNavLeft{
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: white;
  
}
.SubNavLeft li {
  float: left;
}
.BodyText{
  padding: 0 45px;
  overflow: hidden;
  overflow-wrap: anywhere;
}

.TabControl{
  width: 1200px; 
  height: 100%;
  justify-content: center;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.TabPageBig{
  justify-content: center;
  align-items: center;
  width: 1200px;
}
.TabPage{
  justify-content: center;
  align-items: center;
}
.TabPageBig li a {
  display: block;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  color: black;
}

/* Change the link color to #111 (black) on hover */
.TabPageBig li a:hover {
  color: #a8ada8;
}
.TabPageBig .TabActive {
  color: #00bed8;
  font-weight: 800;
}

.TabPage li a {
  display: block;
  text-align: center;
  /* padding: 15px 16px; */
  text-decoration: none;
  color: black;
}

/* Change the link color to #111 (black) on hover */
.TabPage li a:hover {
  color: #a8ada8;
}
.TabPage .TabActive {
  color: #00bed8;
  font-weight: 800;
}

@media screen and (max-width: 1024px)
 {
  #WriteCondition{
    width: 100%;
    font-size: 12px;
    text-align: center;
    line-height: 25px;
  }
  #WriteCondition input{
    font-size: 12px;
    line-height: 25px;
  }
  #WriteCondition select{
    font-size: 10px;
    line-height: 25px;
  }
  .Contents_Active{
    width: 100%;
    border-width: 0px;
    border-style: solid;
    border-color:gainsboro;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
  .SubNavCategory{
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: white;
  }
  .SubNav{
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: white;
    
}
    .Screen{
      width: 100%;
      height: 100%;
      overflow: auto;
    }
    .TabControl{
      width: 100%; 
      margin: 0;
      padding: 0;
      align-items: baseline;
    }
    .TabPage li a {
      display: block;
      text-align: center;
      /* padding: 14px 16px; */
      color: black;
    }
    .TabPage{
      justify-content: center;
    }
 } 
 @media screen and (max-width: 500px)
 {
  #WriteCondition{
    width: 100%;
    font-size: 10px;
    text-align: center;
    line-height: 25px;

  }
  #WriteCondition input{
    font-size: 10px;
    line-height: 25px;
  }
  #WriteCondition select{
    font-size: 10px;
    line-height: 25px;
  }
      .Contents_Active{
    width: 100%;
    border-width: 0px;
    border-style: solid;
    border-color:gainsboro;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
  .SubNavCategory{
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: white;
  }
  .SubNav{
    display: inline-flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 350px; 
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: white;
    
}
    .Screen{
      width: 100%;
      height: 100%;
      overflow: auto;

    }
    .TabControl{
      width: 100%; 
      margin: 0;
      padding: 0;
      align-items: baseline;
    }
    .TabPage li a {
      display: block;
      text-align: center;
      /* padding: 14px 16px; */
      color: black;
    }
    .TabPage{
      justify-content: center;
      width: 100%;
    }
 } 