.CreateRoomScreen .wrap{
    background-color: #00bed8;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;

}
.CreateRoomScreen .wrap label{
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    width: 10%;
}
.CreateRoomScreen input{
    width: 55%;
    margin: 10px;
    padding: 10px;
    height: 50px;
    font-size: 16px;
}
.CreateRoomScreen .wrap button{
    width: 25%;
    height: 50px;
    font-size: 20px;
    margin-right: 0;
    border: none;
    border-radius: 5px;
    background-color: #004A61;
    color: #fff;
}
.CreateRoomScreen .wrap button:hover{
    /* color: #00bed8; */
    background-color: #fff;
    color: #004A61;
    border: 3px solid #004A61;
    cursor: pointer;
    font-weight: bold;
}