body{
  margin: 0;
  
}
.outerContainer {
    display: flex;
    height: 100%;
    justify-content: center;
  }
  .ContainerBox {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #dadada;
    
    border-radius: 8px;
    height: 100%;
    width: 100%;
  }
  
  @media (min-width: 320px) and (max-width: 480px) {
    .outerContainer {
      height: 90vh;
      width: 100%;
    }
    .ContainerBox {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 75%;
      background-color: #1A1A1D;
    }
    .container {
      width: 100%;
      height: 100%;
    }
  }
  
  @media (min-width: 480px) and (max-width: 1200px) {
    .outerContainer {
      height: 100vh;
      width: 100%;
    }
    .ContainerBox {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 75%;
      background-color: #1A1A1D;
    }
    .container {
      width: 100%;
      height: 100%;
    }
  }