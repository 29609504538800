.사진첩_프로필사진{
  border-style: solid;
  border-width: 1px;
  display:flex;
  justify-content:center;
  flex-direction:column;
  width:35%;
  height:100px;
}
.작성자정보{
  display: flex;
  justify-content: flex-start;
}

.WriteModeBoxOff{
  display: none;
  width: 600px;
  margin-bottom: 30px;
}
.WriteModeBox{
  display: flex;
  width: 600px;
  margin-bottom: 30px;
}

.ReadModeBox{
  width: 600px;
  margin-bottom: 30px;
}
.WriteModeHeader{
  display: flex;
  width: 100%;
  height: 90px;
  background-color:white;
  justify-content: flex-start;
}
.WriteModeFooter{
  width: 100%;
  height: 30px;
  background-color: white;
}
.SubmitControl{
  border-style: none;
  border-width: 1px;
  background-color: white;
  box-shadow:0 0 3px silver;
  min-height: 0px;
  width:100%;
  border-radius: 15px;
}
.사진_본문입력기{
  width: 100%;
  min-height: 100px;
  
}
.imgPixHeader{
  width: 100%;
  min-height: 100px;
}
.SubmitButton{
  margin: 10px;
  min-height: 20px;
}
.SubmitButton:active{
  margin: 10px;
  min-height: 20px;
}

 @media screen and (max-width: 500px)
 {
 
 }