.Nav{
  width: 100%; 
  list-style-type: none;
  margin: 0 0 0 100px;
  padding: 0;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  
}
.Nav li a:hover {
  background-color: white;
}
.Nav .active {
  border-bottom-color:  white;
  border-bottom-style: solid;
  border-width: 10px;
}
.App-footer {
  background-color: #00bed8;
  height: 10vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 1vmin);
  position: relative;
  color: white;
  bottom:0;
  margin-bottom: 0px;
}

/*====== 상단 메뉴바======  */
#Metro a:link,#Metro a:visited{
  color: rgb(255, 255, 255) ;
}
#Metro a:active ,#Metro a:hover{
  /* color:  white; ; */
  background-color: transparent;
  border-radius: 10px;
}
#Mlogo a:link,#Mlogo a:visited{
    color: rgb(255, 255, 255) ;
}
#Mlogo a:active ,#Mlogo a:hover{
  color:  white; ;
  background-color:transparent;
  border-radius: 10px;
}
.MButton a{
  margin-top: 20px;
  display: block;
}
.Drawer ul{
  padding-inline-start: 0px;
}
.Drawer ul .MMenu{
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e7e7e7;
  padding: 6px 16px;
}
.MMenu button {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background-color: #f7f7f7;
  border-style: none;
  margin-right: 10px;
}

.SubMenuLi .SubMenu{
  background-color: #f7f7f7;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: max-height .2s ease-in-out;
}
.MMenu .flipImg{
  transform: rotateX(180deg);
}
.MMenu .flipImg.active {
  transform: rotateX(0);
}
.SubMenuLi .visible {
  max-height: 400px;
  /* display: block; */
}
.SubMenu li a {
  display: block;
  min-height: 48px;
  line-height: 1.5;
  display: flex;
  align-items: center;
  text-decoration: none;
}
.SubMenu li a:hover {
  color: #009bb0;
}

.MuiDrawer-paper {
  width: 250px;
 }
 .MuiIconButton-root:hover{
  background-color: transparent;
 }