.my-page-container {
  max-width: 600px;
  margin: 2rem auto;
  padding: 20px;
  background-color: #ffffff;  /* White background */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);  /* Adjusted shadow for better visibility on white */
  border-radius: 8px;
  color: #000000;  /* Black text */
}

.my-page-container h1 {
  text-align: left;
  color: #000000;  /* Black text */
  margin: 17px;
}

.my-page-container table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  border-radius: 1rem;
}

.my-page-container td {
  padding: 13px;
  text-align: left;
  vertical-align: middle;
  font-weight: bold;
}

.my-page-container input[type="text"], input[type="email"], select {
  width: 90%;
  padding: 8px;
  margin: 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.my-page-container input[type="radio"] {
  margin-right: 15px;
}

.my-page-container label {
  margin-right: 10px;
}

.my-page-container .saveBtn, .modifyBtn {
  display: flex;
  justify-content: center;
}

.my-page-container button.signupbtn {
  background-color: #00bed8;  /* Stylish blue */
  font-weight: bold;
  color: white;
  padding: 10px 20px;
  margin: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.my-page-container button.signupbtn:hover {
  background-color: #007BFF;  /* Darker blue on hover */
  color: white;
}

.edit-mode td {
  background-color: #ffffff;  /* White background for edit mode */
}

.my-page-container td:first-child {
  width: 25%;
}

.my-page-container td:last-child {
  width: 100%;
}

.my-page-container .rightLine {
  border-left: 1px solid #000000;  /* Black border */
  margin-right: 2rem;
}

.selectGender {
  border: 1px solid #000000;  /* Black border */
}
