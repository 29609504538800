.CalendarClassic {
  width: 100%;
  margin-right: 50px;
  padding: 0;
  font-family: sans-serif;
  box-sizing: border-box;
}

.CalendarClassic body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.CalendarClassic .calendar {
  width: 60px;
  margin: 50px;
}

.CalendarClassic .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.CalendarClassic .year-month {
  font-size: 35px;
}

.CalendarClassic .nav {
  display: flex;
  border: 1px solid #333333;
  border-radius: 5px;
}

.CalendarClassic .nav-btn {
  width: 28px;
  height: 30px;
  border: none;
  font-size: 16px;
  line-height: 34px;
  background-color: transparent;
  cursor: pointer;
}

.CalendarClassic .go-today {
  width: 75px;
  border-left: 1px solid #333333;
  border-right: 1px solid #333333;
}

.CalendarClassic .days {
  display: flex;
  margin: 25px 0 10px;
}

.CalendarClassic .day {
  width: calc(100% / 7);
  text-align: center;
}

.CalendarClassic .dates {
  display: flex;
  flex-flow: row wrap;
  height: 400px;
  border-top: 1px solid #333333;
  border-right: 1px solid #333333;
  border-style: solid;
}

.CalendarClassic .date {
  width: calc(100% / 7);
  padding: 15px;
  text-align: right;
  border-bottom: 1px solid #333333;
  border-left: 1px solid #333333;
}

.CalendarClassic .day:nth-child(7n + 1),
.CalendarClassic .date:nth-child(7n + 1) {
  color: #d13e3e;
}

.CalendarClassic .day:nth-child(7n), 
.CalendarClassic .date:nth-child(7n) {
  color: #396ee2;
}

.CalendarClassic .other {
  opacity: 0.3;
}

.CalendarClassic .today {
  position: relative;
  color: green;
}

.CalendarClassic .today::before {
  position:absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  width: 30px;
  height: 30px;
  display: block;
  background-color: #ff0000;
  border-radius: 50%;
  content: '';
} 


@media screen and (min-width: 600px) and (max-width : 1024px)
 {
   
.CalendarClassic .year-month {
  font-size: 25px;
}
  .CalendarClassic .days {
    display: flex;
    margin: 5px 0 5px;
  }
  .CalendarClassic {
    width: 100%;
    margin-right: 0px;
    padding: 0;
    font-family: sans-serif;
    box-sizing: border-box;
  }
  .CalendarClassic .dates {
    display: flex;
    flex-flow: row wrap;
    height: 100%;
    border-top: 1px solid #333333;
    border-right: 1px solid #333333;
    border-style: solid;
  }
  .CalendarClassic .date {
    width: calc(100% / 7);
    padding: 5px;
    text-align: right;
    border-bottom: 1px solid #333333;
    border-left: 1px solid #333333;
  }

 }
 @media screen and (max-width: 650px)
 {
   
.CalendarClassic .year-month {
  font-size: 25px;
}
  .CalendarClassic .days {
    display: flex;
    margin: 5px 0 5px;
  }
  .CalendarClassic {
    width: 100%;
    margin-right: 0px;
    padding: 0;
    font-family: sans-serif;
    box-sizing: border-box;
  }
  .CalendarClassic .dates {
    display: flex;
    flex-flow: row wrap;
    height: 100%;
    border-top: 1px solid #333333;
    border-right: 1px solid #333333;
    border-style: solid;
  }
  .CalendarClassic .date {
    width: calc(100% / 7);
    padding: 5px;
    text-align: right;
    border-bottom: 1px solid #333333;
    border-left: 1px solid #333333;
  }
  
 
 }
 