@font-face {
  font-family: "Pretendard-Medium";
  font-weight: 50;
  src: url("./font/Pretendard-Medium.woff") format("truetype");
}
@font-face {
  font-family: "Pretendard-Light";
  font-weight: 50;
  src: url("./font/Pretendard-Light.otf") format("truetype");
}
@font-face {
  font-family: "Pretendard-SemiBold";
  font-weight: 50;
  src: url("./font/Pretendard-SemiBold.otf") format("truetype");
}
@font-face {
  font-family: "Pretendard-Regular";
  font-weight: 50;
  src: url("./font/Pretendard-Regular.otf") format("truetype");
}
@font-face {
  font-family: "Pretendard-ExtraBold";
  font-weight: 1000;
  src: url("./font/Pretendard-ExtraBold.otf") format("truetype");
}

*{
  font-family: 'Pretendard-Light';
}

.ql-editor{
min-height: 150px;
}
.App {
  margin-bottom: 0px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#Contents{
  /* justify-content: center;
  align-items : center; */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
  background-color:white;
  min-height: 4vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(17px + 1vmin);
  color: black;
}


.App-footer {
  background-color: #00bed8;
  height: 10vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 1vmin);
  position: relative;
  color: white;
  bottom:0;
  margin-bottom: 0px;
}
.App-header > a:link,a:visited{
  color: rgb(255, 255, 255) ;
 }
 .App-header >  a:active,a:hover{
  color:  #00bed8; ;
 }
.App-link {
  color: #61dafb;
}
.MasterPanel {
  /* justify-content: center;
  display: flex; */
  min-height: 100vh;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 1024px)
{
  #Contents{
    /* justify-content: center;
    align-items : initial; */
  }
  .App-header > a:link,a:visited{
    color: rgb(255, 255, 255) ;
    /* color: black; */
   }
   .App-header >  a:active,a:hover{
    color:  #009bb0; ;
   }
  .App-footer {
    background-color: #00bed8;
    width: 100%;
    height: 11vh;
    display: flex;
    align-items: center;
    font-size: calc(4px + 2vmin);
    color: white;
    position: relative;
    bottom:0;
  }
  .MasterPanel {
    /* justify-content: center;
    display: flex;
    width:100%;
    height: 89vh; */
  }
}
