.ThumbnailViewer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: space-between;
  flex-wrap: nowrap;
  height: 400px;
  width: 280px;
}
#NewPhotoThumbnail{
  margin-right: 15px;
  margin-bottom: 15px;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  border-color: #f1f1f1;
  box-shadow: 2px 2px 2px 2px #f1f1f1
}
#NewPhotoThumbnail img{
  object-fit: cover;
}
#ThumbnailTitle{
  background-color: #333;
  color: white;
}
.thumbnail {
  position: relative;
  width: 185px;
  height: 190px;
  cursor: pointer;
}

.thumbnail-img {
  position: relative;
  border-radius: 10px;
  width: 185px;
  height: 190px;
}

.thumbnail-label {
  line-height: 30px;
  position: absolute;
  background-color: black;
  width: 100%;
  bottom: -2px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  opacity: 0.8;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.thumbnail-text {
  text-align: center;
  font-size: 11pt;
  color: white;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
@media screen and (max-width: 1024px)
 {
  

 }

 @media screen and (max-width: 500px)
 {
  .Contents{
    height: auto;
  }
  .비밀번호찾기 .비밀번호찾기:active,.비밀번호찾기:link,.비밀번호찾기:hover,.비밀번호찾기:visited{
    color: black;
  }
  .LoginTable li{
    list-style-type: none;
    line-height: 40px;
  }
  .ThumbnailViewer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: space-between;
    flex-wrap: nowrap;
    height: auto;
    width: 280px;
    margin-bottom: 50px;
  }

 } 