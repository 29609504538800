* {
    box-sizing: border-box;
  }
  
  /* Position the image container (needed to position the left and right arrows) */
  .GalleryContainer {
    position: relative;
    /* width: 100%; */
    border-radius: 20px;
    /* padding: 3px; */
  }
  
  /* Hide the images by default */
  
  .mySlides img{
    width: 100%;
  }
  
  /* Add a pointer when hovering over the thumbnail images */
  .cursor {
    cursor: pointer;
  }
  #caption{
    height: 10px;
  }

  /* Next & previous buttons */
  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -50px;
    color: white;
    font-weight: bold;
    font-size: 20px;
    border-radius: 0 3px 3px 0;
    user-select: none;
    -webkit-user-select: none;
    transform: translateY(50%);
  }
  
  /* Position the "next button" to the right */
  .next {
    right: 0.1%;
    border-radius: 3px 0 0 3px;
  }
  .prev {
    left: 0.1%;
    border-radius: 3px 0 0 3px;
  }
  
  /* On hover, add a black background color with a little bit see-through */
  .prev:hover,
  .next:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  /* Number text (1/3 etc) */
  .numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
    
  }
  
  /* Container for image text */
  .caption-container {
    text-align: center;
    background-color: #222;
    color: white;
    
  }
  .row{
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    width: 20%;
  }
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  /* Six columns side by side */
  .column {
    width: 15px;
    height: 15px;
    background-color: #00bed8;
    border-radius: 50px;
    margin: 0 5px;
  }
  
  /* Add a transparency effect for thumnbail images */
  .demo {
    opacity: 0.6;
    width: 100%;
    height: 100%;
    /* border-radius: 50px; */
  }
  
  .GalleryActive,
  .demo:hover {
    opacity: 1;
  }
  .GalleryActive img{
    width: 100px;
  }

  @media screen and (min-width: 1024px) {
    .defaultImg{
      display: block;
    }
    .MbannerImg{
      display: none;
    }
    
  }

  @media screen and (min-width: 600px) and (max-width : 1024px){
    .GalleryContainer{
      width: 100%;
    }
    .defaultImg{
      display: none;
    }
    .MbannerImg{
      display: block;
      width: 100%;
    }
  }
  @media screen and (max-width: 600px){
    .GalleryContainer{
      width: 100%;
    }
    .defaultImg{
      display: none;
    }
    .MbannerImg{
      display: block;
      
    }
  }